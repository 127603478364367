var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticClass:"pb-10"},[_c('v-system-bar',{attrs:{"height":"47","color":"#aeddff"}},[_c('h3',{staticClass:"black--text pl-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('v-card',{staticClass:"mb-10"},[_c('v-system-bar',{attrs:{"height":"47","color":"#aeddff"}},[_c('h3',{staticClass:"black--text"},[_vm._v("Pending Tasks")])]),_c('v-card-text',[(_vm.tasks.length > 0)?_vm._l((_vm.tasks),function(n){return _c('div',{key:n.id},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_vm._v(" "+_vm._s(n.name)+" "),_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(_vm.sinceDate(n.created_at))+" ")]),_c('br'),(!n.read)?_c('span',{on:{"click":function($event){return _vm.viewTask(n)}}},[_c('el-link',{attrs:{"href":"#","type":n.text.includes('rejected')
                                ? 'danger'
                                : 'default'},on:{"click":function($event){return _vm.viewTask(n)}}},[_vm._v(" "+_vm._s(n.text)+" ")])],1):_c('span',{class:n.text.includes('rejected') ? 'red--text' : '',staticStyle:{"cursor":"hand"},on:{"click":function($event){return _vm.viewTask(n)}}},[_vm._v(" "+_vm._s(n.text)+" ")])])])])}):_c('div',[_vm._v("There are no Pending Tasks")])],2)],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('v-card',{staticClass:"pb-10"},[_c('v-system-bar',{attrs:{"height":"47","color":"#aeddff"}},[_c('h3',{staticClass:"black--text"},[_vm._v("Notifications")])]),_c('v-card-text',[(_vm.notifications.length > 0)?_vm._l((_vm.notifications),function(n){return _c('div',{key:n.id},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_vm._v(" "+_vm._s(n.name)+" "),_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(_vm.sinceDate(n.created_at))+" ")]),_c('br'),(!n.read)?_c('span',{on:{"click":function($event){_vm.updateRead(n);
                            _vm.viewTask(n);}}},[_c('el-link',{attrs:{"href":"#","type":n.text.includes('rejected')
                                ? 'danger'
                                : 'default'},on:{"click":function($event){_vm.updateRead(n);
                              _vm.viewTask(n);}}},[_vm._v(" "+_vm._s(n.text)+" ")])],1):_c('span',{class:n.text.includes('rejected') ? 'red--text' : '',staticStyle:{"cursor":"hand"},on:{"click":function($event){return _vm.viewTask(n)}}},[_vm._v(" "+_vm._s(n.text)+" ")])])])])}):_c('div',[_vm._v("There are no Notifications")])],2)],1)],1)])])],1),_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":0.7}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }