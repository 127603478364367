<template>
  <v-row justify="center">
    <v-container class="pa-0">
      <v-card class="pb-10">
        <v-system-bar height="47" color="#aeddff">
          <h3 class="black--text pl-2">
            {{ title }}
          </h3>

          <v-spacer></v-spacer>
        </v-system-bar>
        <v-divider />
        <v-card-text>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <v-card class="mb-10">
                <v-system-bar height="47" color="#aeddff">
                  <h3 class="black--text">Pending Tasks</h3>
                </v-system-bar>
                <v-card-text>
                  <template v-if="tasks.length > 0">
                    <div v-for="n in tasks" :key="n.id">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          {{ n.name }}
                          <span style="float: right">
                            {{ sinceDate(n.created_at) }}
                          </span>
                          <br />
                          <span v-if="!n.read" @click="viewTask(n)">
                            <el-link
                              href="#"
                              :type="
                                n.text.includes('rejected')
                                  ? 'danger'
                                  : 'default'
                              "
                              @click="viewTask(n)"
                            >
                              {{ n.text }}
                            </el-link>
                          </span>
                          <span
                            v-else
                            @click="viewTask(n)"
                            style="cursor: hand"
                            :class="
                              n.text.includes('rejected') ? 'red--text' : ''
                            "
                          >
                            {{ n.text }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-else>There are no Pending Tasks</div>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <v-card class="pb-10">
                <v-system-bar height="47" color="#aeddff">
                  <h3 class="black--text">Notifications</h3>
                </v-system-bar>
                <v-card-text>
                  <template v-if="notifications.length > 0">
                    <div v-for="n in notifications" :key="n.id">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          {{ n.name }}
                          <span style="float: right">
                            {{ sinceDate(n.created_at) }}
                          </span>
                          <br />
                          <span
                            v-if="!n.read"
                            @click="
                              updateRead(n);
                              viewTask(n);
                            "
                          >
                            <el-link
                              href="#"
                              :type="
                                n.text.includes('rejected')
                                  ? 'danger'
                                  : 'default'
                              "
                              @click="
                                updateRead(n);
                                viewTask(n);
                              "
                            >
                              {{ n.text }}
                            </el-link>
                          </span>
                          <span
                            v-else
                            style="cursor: hand"
                            @click="viewTask(n)"
                            :class="
                              n.text.includes('rejected') ? 'red--text' : ''
                            "
                          >
                            {{ n.text }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-else>There are no Notifications</div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <!--<v-divider />-->
      </v-card>
      <v-overlay :value="loading" :opacity="0.7">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  Option,
  DatePicker,
  Table,
  TableColumn,
  Link,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Link);
Vue.use(Table);
Vue.use(TableColumn);
import Swal from "sweetalert2";
export default {
  created() {
    this.getRecord();
    this.timer = setInterval(this.getNotifications, 300000);
  },
  watch: {
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
    terminated: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    var checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please input the Number"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("Please input digits"));
        } else {
          if (value <= 0) {
            callback(new Error("Number must be greater than 0"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      end_point: "dashboard/",
      title: "Dashboard",
      timer: null,
      approve_ot: false,
      form: {
        id: "",
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      },
      formEmployeeInfo: null,
      leaves: [],
      notifications: [],
      payslips: [],
      tasks: [],
      showLeaveRequestForm: false,
      leaveRequest: null,
      showForgotClockScreen: false,
      forgotClocking: null,
      showOTList: false,
      view: "view",
      rules: {
        name: [
          { required: true, message: "Please enter Name", trigger: "blur" },
        ],
        work_hours: [{ validator: checkNumber, trigger: "blur" }],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    employment_type_changed(value) {
      if (!this.empty(value)) {
        this.form.work_hours = value.work_hours;
      }
    },
    showOtScreen() {
      this.$router.push("/approve-ot");
    },
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewTask(row) {
      let f = {
        completed: row.completed,
        created_at: row.created_at,
        id: row.document_id,
        task_id: row.id,
        module: row.module,
        modules_id: row.modules_id,
        name: row.name,
        text: row.text,
      };
      if (row.module == "Leave Requests") {
        this.leaveRequest = f;
        this.showLeaveRequestForm = true;
      } else if (row.module == "Forgot Clockings") {
        this.forgotClocking = f;
        console.log(this.forgotClocking);
        this.showForgotClockScreen = true;
      }
    },
    updateRead(row) {
      row.read = true;
      this.api(this.end_point + "read-notification", row)
        .then(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getNotifications() {
      this.api(this.end_point + "get-notifications")
        .then((response) => {
          this.notifications = response.notifications;
          let n = this.notifications.filter((e) => e.read == false);
          n.forEach((el) => {
            this.desktopNotify(el.text);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableRecord(enable) {
      var title = "Do you want Enable the record?";
      var text = "Once enabled users can view the record";
      var button = "Yes, enable it!";
      if (!enable) {
        title = "Do you want to disabled the record?";
        text = "Once disabled users wont be able to view the records";
        button = "Yes, disable it!";
      }
      Swal.fire({
        title: title,
        text: text,
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: button,
      }).then((result) => {
        if (result.value) {
          this.change_status(enable);
        }
      });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.showLeaveRequestForm = true;
    },
    newForgotClocking() {
      this.forgotClocking = null;
      this.showForgotClockScreen = true;
    },
    getRecord() {
      let f = {
        id: null,
      };
      this.loading = true;
      this.api(this.end_point + "dashboard", f)
        .then((response) => {
          this.loading = false;
          this.formEmployeeInfo = response.me;
          this.leaves = response.leaves;
          this.notifications = response.notifications;
          this.permissions = response.permissions;
          this.approve_ot = response.approve_ot;
          this.tasks = response.tasks;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
    },
  },
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
  },
  name: "Dashbaord",
};
</script>
